<template>
	<section class="invoice-add-wrapper">
	  <b-row class="invoice-add">
  
		<!-- Col: Left (Invoice Container) -->
		<b-col
		  cols="12"
		  xl="9"
		  md="8"
		>
		  <b-form @submit.prevent>
			<b-card
			  no-body
			  class="invoice-preview-card"
			>
			  <!-- Header -->
			  <b-card-body class="invoice-padding pb-0">
  
				<div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
  
				  <!-- Header: Right Content -->
				  <div class="invoice-number-date mt-md-0 mt-2">
					<div class="d-flex align-items-center justify-content-md-end mb-1">
						<span class="title font-weight-bold">
					    <feather-icon icon="ClipboardIcon" class="" size="16"/> Movimiento:
					  </span>
					  <b-input-group class="input-group-merge invoice-edit-input-group disabled">
						<b-input-group-prepend is-text>
						  <feather-icon icon="HashIcon" />
						</b-input-group-prepend>
						<b-form-input
						  id="invoice-data-id"
						  v-model="invoiceData.id"
						  disabled
						/>
					  </b-input-group>
					</div>
				  </div>
				  <div class="d-flex align-items-center mb-1">
					  <span class="title mr-2 font-weight-bold">
						<feather-icon icon="CalendarIcon" class="" size="16"/> Fecha:
					  </span>
					  <flat-pickr
						v-model="invoiceData.fecha"
						class="form-control invoice-edit-input"
					  />
				  </div>				  
				</div>
				<div class="d-flex align-items-center">
					  <span class="title font-weight-bold" style="width: 15% !important;">
						<feather-icon icon="GridIcon" class="" size="16"/> Malla:
					  </span>
					  <div style="width: 85% !important;">
						<!-- Select Client -->
						<v-select
							v-model="invoiceData.centro_costo_padre_id"
							:options="mallas"
							@input="getCentrosTrabajo()"
							label="Nombre"
							:clearable="false"
							></v-select>
					  </div>
					
				  </div>
				<div class="d-flex align-items-center mt-2">
					  <span class="title font-weight-bold" style="width: 15% !important;">
						<feather-icon icon="LayersIcon" class="" size="16"/> Centro de Trabajo:
					  </span>
					  <div style="width: 85% !important;">
						<!-- Select Client -->
						<v-select
							v-model="invoiceData.centro_costo_id"
							:options="centros_trabajo"
							@input="actualizarPagos()"
							label="Nombre"
							input-id="invoice-data-client"
							:clearable="false"
							></v-select>
					  </div>
					
				  </div>
				  <pre class="mt-1 d-flex justify-content-arround" v-if="invoiceData.centro_costo_id != null">
					<div>
						<b class="mt-1">COD:</b> {{invoiceData.centro_costo_id.Codigo}} 
					</div>
					<div>
						<b class="mt-1">NOMBRE:</b> {{invoiceData.centro_costo_id.Nombre}} 
					</div>
					<div>
						<b class="mt-1">PAGO POR HORA:</b> {{invoiceData.centro_costo_id.Pago}}
					</div>
				  </pre>
				
			  </b-card-body>
  
			  <!-- Spacer -->
			  <hr class="invoice-spacing">
  
			  <!-- Invoice Client & Payment Details -->
			  <b-card-body
				class="invoice-padding pt-0"
			  >
				
			  </b-card-body>
  
			  <!-- Items Section -->
			  <b-card-body class="invoice-padding form-item-section">
				<div ref="form" class="repeater-form" :style="{height: trHeight}">
				  <b-row
					v-for="(item, index) in invoiceData.items"
					:key="index"
					ref="row"
					class="pb-2"
				  >
  
					<!-- Item Form -->
					<!-- ? This will be in loop => So consider below markup for single item -->
					<b-col cols="12">
  
					  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
					  <div class="d-none d-lg-flex">
						<b-row class="flex-grow-1 px-1">
						  <!-- Single Item Form Headers -->
						  <b-col
							cols="12"
							lg="5"
						  >
						  <feather-icon icon="UserIcon" class="" size="16"/> Empleado
						  </b-col>
						  <b-col
							cols="12"
							lg="3"
						  >
						  <feather-icon icon="ClockIcon" class="" size="16"/> Horas Trabajadas
						  </b-col>
						  <b-col
							cols="12"
							lg="2"
						  >
						  <feather-icon icon="DollarSignIcon" class="" size="16"/> Pago
						  </b-col>
						  
						</b-row>
						<div class="form-item-action-col" />
					  </div>
  
					  <!-- Form Input Fields OR content inside bordered area  -->
					  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
					  <div class="d-flex border rounded">
						<b-row class="flex-grow-1 p-2">
						  <!-- Single Item Form Headers -->
						  <b-col
							cols="12"
							lg="5"
						  >
							<label class="d-inline d-lg-none">Empleado</label>
							<v-select
							  v-model="item.empleado"
							  :options="itemsEmpleados"
							  label="Nombre"
							  :clearable="true"
							  class="mb-2 item-selector-title"
							  placeholder="Seleccionar Empleado"
							  @input="val => updateItemForm(index, val)"
							/>
						  </b-col>
						  <b-col
							cols="12"
							lg="3"
						  >
							<label class="d-inline d-lg-none">Horas Trabajadas</label>
							<b-form-input
							  v-model="item.horas_trabajadas"
							  @input="calcularTotalPago(item)"
							  type="number"
							  class="mb-2"
							/>
						  </b-col>
						  <b-col
							cols="12"
							lg="3"
						  >
							<label class="d-inline d-lg-none">Pago</label>
							<b-form-input
							  v-model="item.pago"
							  type="number"
							  class="mb-2"
							/>
						  </b-col>
						 
						 
						</b-row>
						<div class="d-flex flex-column justify-content-between border-left py-50 px-25">
						  <feather-icon
							size="16"
							icon="XIcon"
							class="cursor-pointer"
							@click="removeItem(index)"
						  />
						 
						</div>
					  </div>
					</b-col>
				  </b-row>
				</div>
				<b-button
				  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				  size="sm"
				  variant="primary"
				  @click="addNewItemInItemForm"
				>
				<feather-icon icon="UserPlusIcon" class=""/>  Agregar Empleado 
				</b-button>
			  </b-card-body>
  
			
  
			  <!-- Spacer -->
			  <hr class="invoice-spacing">
  
			  <!-- Note -->
			  <b-card-body class="invoice-padding pt-0">
				<span class="font-weight-bold">Observaciones: </span>
				<b-form-textarea v-model="invoiceData.observaciones" />
			  </b-card-body>
			</b-card>
		  </b-form>
		</b-col>
  
		<!-- Right Col: Card -->
		<b-col
		  cols="12"
		  md="4"
		  xl="3"
		  class="invoice-actions mt-md-0 mt-2"
		>
  
		  <!-- Action Buttons -->
		  <b-card>
  
			<!-- Button: Send Invoice -->
			<b-button
			  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			  variant="primary"
			  class="mb-75"
			  block
			  @click="guardarMovimiento()"
			>
			<feather-icon icon="SaveIcon" class=""/> Guardar Movimiento
			</b-button>
  
			<!-- Button: DOwnload -->
			<b-button
			  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			  variant="outline-primary"
			  class="mb-75"
			  block
			>
			<feather-icon icon="FileIcon" class=""/> Imprimir PDF 
			</b-button>
  
			<!-- Button: Print 
			<b-button
			  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			  variant="outline-primary"
			  block
			>
			<feather-icon icon="PrinterIcon" class=""/>  Imprimir para Firmas
			</b-button>-->
		  </b-card>

		  <!-- <pre>
			{{ invoiceData }}
		  </pre> -->
		</b-col>
	  </b-row>
	</section>
  </template>
  
  <script>
  import Logo from '@core/layouts/components/Logo.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { heightTransition } from '@core/mixins/ui/transition'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store'
  import {
	BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import flatpickr1 from "flatpickr";
	import { Spanish } from "flatpickr/dist/l10n/es.js"
	flatpickr1.localize(Spanish); // default locale is now Spanish
  import flatPickr from 'vue-flatpickr-component'
  import invoiceStoreModule from '../../apps/invoice/invoiceStoreModule'
  import axios from '@axios'
  
  export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BButton,
		BCardText,
		BForm,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BFormTextarea,
		BFormCheckbox,
		BPopover,
		flatPickr,
		vSelect,
		Logo,
	},
	directives: {
		Ripple,
		'b-toggle': VBToggle, 
	},
	mixins: [heightTransition],
	mounted() {
		this.getMallas(),
		this.initTrHeight(),
		//this.getCentrosTrabajo(),
		this.getEmpleados()
	},
	created() {
	  	window.addEventListener('resize', this.initTrHeight)
	},
	destroyed() {
	  	window.removeEventListener('resize', this.initTrHeight)
	},
	methods: {
	actualizarPagos(){
		//recorrer todos los items que se han agregado
		this.invoiceData.items.forEach(
			(item) => {
				item.pago = item.horas_trabajadas * this.invoiceData.centro_costo_id.Pago;
			}
		);	
	},
	guardarMovimiento(){
		axios.post('/api/movimientos', this.invoiceData).then(
			(response) => {
				this.$toast.success('Movimiento guardado correctamente');
				//return to back
				this.$router.push('/movimientos/captura-movimientos');
			}
		).catch(
			(error) => {
				this.$toast.error('Ocurrio un error al guardar el movimiento');
			}
		);
	},
	calcularTotalPago(item){
		//tomar el valor de las horas que se estan escribiendo
		let horas = item.horas_trabajadas;
		if(this.invoiceData.centro_costo_id == null){
			this.$toast.error('Selecciona un centro de trabajo para calcular el pago');
			return;
		}

		//calcular el total del pago
		let pago = horas * this.invoiceData.centro_costo_id.Pago;

		//asignar el total del pago
		item.pago = pago;

	},
	getMallas() {
      axios.get('/api/cat-mallas')
        .then(response => {
          this.mallas = response.data.data;
		 
        })
    },
	getCentrosTrabajo(){
		axios.get('/api/cat-centros-costo/by-malla/' + this.invoiceData.centro_costo_padre_id.Codigo)
        .then((response) => {
          this.centros_trabajo = response.data.data;
        })
        .catch((error) => {
          console.log(error)
        })
	},
	getEmpleados(){
		axios.get('/api/cat-empleados')
        .then((response) => {
          this.itemsEmpleados = response.data.data;
        })
        .catch((error) => {
          console.log(error)
        })
	},
	formatMoney(value) {
				//format to mexican currency
				var formatter = new Intl.NumberFormat('es-MX', {
				style: 'currency',
				currency: 'MXN',
				minimumFractionDigits: 2
				});

				return formatter.format(value);
			},
	  addNewItemInItemForm() {
		this.$refs.form.style.overflow = 'hidden'
		this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
  
		this.$nextTick(() => {
		  this.trAddHeight(this.$refs.row[0].offsetHeight)
		  setTimeout(() => {
			this.$refs.form.style.overflow = null
		  }, 350)
		})
	  },
	  removeItem(index) {
		this.invoiceData.items.splice(index, 1)
		this.trTrimHeight(this.$refs.row[0].offsetHeight)
	  },
	  initTrHeight() {
		this.trSetHeight(null)
		this.$nextTick(() => {
		  this.trSetHeight(this.$refs.form.scrollHeight)
		})
	  },
	},
	setup() {
	  const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
  
	  // Register module
	  if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
  
	  // UnRegister on leave
	  onUnmounted(() => {
		if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
	  })
  
	  /*const clients = ref([])
	  store.dispatch('app-invoice/fetchClients')
		.then(response => { clients.value = response.data })*/
  
	  const itemFormBlankItem = {
		empleado: null,
		horas_trabajadas: null,
		pago: 0,
		observacion: '',
	  }
  
	  const invoiceData = ref({
		id: null,
		fecha: new Date(),
		centro_costo_padre_id : null,
		centro_costo_id: null,
  
		// ? Set single Item in form for adding data
		items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
  		observaciones: '',
	  })
  
	  const updateItemForm = (index, val) => {
		const { cost, qty, description } = val
		invoiceData.value.items[index].cost = cost
		invoiceData.value.items[index].qty = qty
		invoiceData.value.items[index].description = description
	  }
  
	  const paymentMethods = [
		'Bank Account',
		'PayPal',
		'UPI Transfer',
	  ]
  
	  return {
		invoiceData,
		//clients,
		//itemsOptions,
		updateItemForm,
		itemFormBlankItem,
		paymentMethods,
	  }
	},
	data() {
	  return {
		mallas : [],
		centros_trabajo: [],
		itemsEmpleados : [],
	  }
	},
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .invoice-add-wrapper {
	.add-new-client-header {
	  padding: $options-padding-y $options-padding-x;
		color: $success;
  
	  &:hover {
		background-color: rgba($success, 0.12);
	  }
	}
  }
  </style>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '~@core/scss/base/components/variables-dark';
  
  .form-item-section {
  background-color:$product-details-bg;
  }
  
  .form-item-action-col {
	width: 27px;
  }
  
  .repeater-form {
	// overflow: hidden;
	transition: .35s height;
  }
  
  .v-select {
	&.item-selector-title,
	&.payment-selector {
	  background-color: #fff;
  
	  .dark-layout & {
		background-color: unset;
	  }
	}
  }
  
  .dark-layout {
	.form-item-section {
	  background-color: $theme-dark-body-bg;
  
	  .row .border {
		background-color: $theme-dark-card-bg;
	  }
  
	}
  }
  </style>
  